import '../../styles/pages/spring-summer-2023/presidents-message.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2023-stories/more-stories"

import framSignature from "../../images/spring-summer-2023/presidents-message/fram-sig.svg";
import socialBanner from "../../images/spring-summer-2023/social-banners/presidents-message.jpg";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'By now many of you know that I have decided to retire from the position as your Cal State Fullerton president, effective July 31, 2023.';
    var slug = 'presidents-message';

    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <div className="title-block animated fadeIn">
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
        </div>
        <article className="wrap small">

          <p><span className="intro-text">By now many of you know that I have decided</span> to retire from the position as your Cal State Fullerton president, effective July 31, 2023. This was an incredibly hard decision — I love CSUF and the Titan family deeply, and as president over the past five years, I have dedicated my entire being to this great institution and the students, faculty, staff and community we serve.</p>

          <p>It is impossible to express to you in words what being your president has meant to me. I am so proud of all we have accomplished together. The stories in this issue of Titan magazine offer yet another example of that transformative work.</p>

          <p>Cal State Fullerton is not only the largest undergraduate university in California, but it is also No. 1 in California for graduating women — like Kelsie Whitmore ’20, ’22, the first woman to play in a Major League Baseball partner league, and civil engineering alumna Chloe Gharios Osorio ’17, who is empowering other women to pursue careers in STEM through the #stompingoutstereotypes movement.</p>

          <p>We, at CSUF, boast the second-largest business school in the nation, graduating Titans like Truff hot sauce co-founder Nick Ajluni ’15. He and communications alumnus Nick Guillen ’15 are just a few examples of the trailblazing entrepreneurs who gained knowledge, mentorship and hands-on opportunities here in our Titan halls.</p>

          <p>You’ll also read the incredible success story of our “It Takes a Titan” comprehensive philanthropic campaign, which raised an astounding $270 million — surpassing our initial $175 million goal — to advance the university’s mission well into the future.</p>

          <p>My fellow Titans, Julie and I could not be more proud of the life-changing work that we have done together with you, and the thousands of Titans we have met along the way who exemplify the Titan definition: “One who stands out for greatness of achievement.”</p>

          <p>Of course, the journey is not yet over. We have the foundational groundwork for establishing a new strategic plan, and we cannot and will not take our eye off our continued commitment to diversity, equity, inclusion and social justice. If there has been a single defining principle or emphasis of my time at CSUF, it has been our unwavering and complete devotion to creating a community where all can thrive and reach their full potential.</p>

          <p>Julie and I look forward to supporting our beloved university for decades to come as it soars to even greater heights under the leadership of a new president. For now, I just want to say thank you. It has been a true blessing and honor to serve with you.</p>

          <p style={{lineHeight: '1.2em'}}>
            Always a Titan,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 